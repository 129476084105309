import { template as template_1ae4daf5e6e74adc91b360ad2057b0e4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1ae4daf5e6e74adc91b360ad2057b0e4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
