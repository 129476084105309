import { template as template_f668ccf444f441d084b9535965c3eb95 } from "@ember/template-compiler";
const FKText = template_f668ccf444f441d084b9535965c3eb95(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
