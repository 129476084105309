import { template as template_44ab89f7aa0d44a3abe77c6013f75e4f } from "@ember/template-compiler";
const WelcomeHeader = template_44ab89f7aa0d44a3abe77c6013f75e4f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
